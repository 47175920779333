import React, { FC, useEffect, useState } from 'react';
import { graphql, Link } from 'gatsby';

import { Container } from 'layout';
import Layout from 'layout/Layout';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import Breadcrumbs from 'components/Breadcrumbs';
import SimpleBanner from 'components/SimpleBanner';
import { createOtherPagesList, createSitemapList } from 'utils/createSitemap';

import Arrow from '../../common/icons/arrow-right.svg';
import { SitemapItem, SitemapLink, SitemapPageTypes } from './models';

import './SitemapPage.scss';

const SitemapPage: FC<SitemapPageTypes.SitemapPageType> = ({
  data: { sitemapPage, homepage, allSitePage },
}) => {
  const { seoMetaTitle, seoMetaDescription, seoMetaKeywords, seoExternalHreflangs } =
    sitemapPage.seo;
  const {
    title,
    level,
    banner,
    homepageLinkText,
    otherPagesGroupText,
    firstLevelPages,
    url,
    lang,
  } = sitemapPage;

  const [sitemap, setSitemap] = useState<SitemapItem[]>();
  const [firstLevel, setFirstLevel] = useState<SitemapLink[]>([]);
  const [otherPages, setOtherPages] = useState<SitemapLink[]>([]);

  useEffect(() => {
    const homepageNode = { title: homepageLinkText, path: homepage?.url };
    const firstLevelPagesTransformed = firstLevelPages.map((prop) => ({
      title: prop.name,
      path: prop.url,
    }));
    const firstLevelArray: SitemapLink[] = [homepageNode, ...firstLevelPagesTransformed];
    setFirstLevel(firstLevelArray);
    setSitemap(createSitemapList(firstLevelArray, allSitePage.nodes));
    setOtherPages(createOtherPagesList(allSitePage.nodes));
  }, []);

  return (
    <Layout headerTransparent>
      <Seo
        {...{
          seo: sitemapPage.seo,
          openGraph: sitemapPage.openGraph,
          pageTitle: title,
          seoExternalHreflangs,
          pageUrl: url,
          lang,
        }}
      />
      <PageSchema
        type="WebPage"
        name={seoMetaTitle}
        data={{
          metaTitle: seoMetaTitle,
          metaDescription: seoMetaDescription,
          metaKeywords: seoMetaKeywords,
        }}
      />
      <div className="banners">
        <Breadcrumbs level={level} />
        <SimpleBanner {...banner} />
      </div>
      <div id="modules">
        <section className="sitemap">
          <Container>
            <div className="sitemap__main-container">
              <ul className="sitemap__list sitemap__main-list">
                {firstLevel?.map((item) => (
                  <li key={item.path} className="sitemap__main-item">
                    <a href={`#${item.title}`} className="sitemap__main-link">
                      <span className="sitemap__main-link-text">{item.title}</span>
                      <span className="sitemap__main-link-icon">
                        <Arrow aria-hidden="true" focusable="false" />
                      </span>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div className="sitemap__pages">
              <ul className="sitemap__list">
                {sitemap?.map(({ item, children }) => (
                  <li key={item.path} id={item.title} className="sitemap__pages-first-item">
                    <Link to={item.path} className="sitemap__pages-first-link">
                      {item.title}
                    </Link>
                    {children.length ? (
                      <ul className="sitemap__list sitemap__pages-list--margin sitemap__pages-list-column">
                        {children.map((itemTwo) => (
                          <li key={itemTwo.item.path} className="sitemap__pages-item">
                            <Link
                              to={itemTwo.item.path}
                              className="sitemap__pages-link sitemap__pages-second-link"
                            >
                              {itemTwo.item.title}
                            </Link>
                            {itemTwo.children ? (
                              <ul className="sitemap__list sitemap__pages-list">
                                {itemTwo.children?.map((itemThree) => (
                                  <li key={itemThree.item.path} className="sitemap__pages-item">
                                    <Link
                                      to={itemThree.item.path}
                                      className="sitemap__pages-link sitemap__pages-third-link"
                                    >
                                      {itemThree.item.title}
                                    </Link>
                                  </li>
                                ))}
                              </ul>
                            ) : null}
                          </li>
                        ))}
                      </ul>
                    ) : null}
                  </li>
                ))}
                <li key={otherPagesGroupText} className="sitemap__pages-first-item">
                  <span className="sitemap__pages-first-link sitemap__pages-first-link--span">
                    {otherPagesGroupText}
                  </span>
                  {otherPages ? (
                    <ul className="sitemap__list sitemap__pages-list--margin sitemap__pages-list-column">
                      {otherPages.map((item) => (
                        <li key={item.path} className="sitemap__pages-item">
                          <Link
                            to={item.path}
                            className="sitemap__pages-link sitemap__pages-second-link"
                          >
                            {item.title}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  ) : null}
                </li>
              </ul>
            </div>
          </Container>
        </section>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($url: String) {
    sitemapPage(url: { eq: $url }) {
      pageId
      level
      lang
      url
      seo {
        ...SEOStructureFragment
      }
      openGraph {
        ...OpenGraphFragment
      }
      title
      banner {
        ...FragmentSimpleBanner
      }
      homepageLinkText
      otherPagesGroupText
      firstLevelPages {
        name
        url
      }
    }
    homepage {
      url
    }
    allSitePage {
      nodes {
        id
        path
        componentChunkName
        context {
          level
          title
          lang
          name
          pageConfiguration {
            hideFromInternalSearch
            hideFromBreadcrumbs
            hideFromSitemap
          }
        }
      }
    }
  }
`;

export default SitemapPage;
