import { AllSitePageNodeType, DEFAULT_EXCLUDED_PAGES } from 'hooks/useSiteTree';
import { SitemapItem, SitemapLink } from 'templates/SitemapPage/models';

import { SECOND_LEVEL_PAGE_LEVEL, THIRD_LEVEL_PAGE_LEVEL } from './constants';

const ldsPageComponentChunkName: string =
  'component---gatsby-theme-husky-src-templates-lds-page-lds-page-tsx';

const articlePageComponentChunkName: string =
  'component---gatsby-theme-husky-src-templates-article-page-article-page-tsx';

const sortSitemapPagesByTitle = (pages: AllSitePageNodeType[]): AllSitePageNodeType[] =>
  pages.sort((a, b) => {
    const c = a.context.title || '';
    const d = b.context.title || '';

    return c.localeCompare(d);
  });

const createThirdLevel = (
  filteredPages: AllSitePageNodeType[],
  previousPath: string
): SitemapItem[] => {
  const sitemapPages = filteredPages.filter(
    (item) =>
      item.context.level === THIRD_LEVEL_PAGE_LEVEL &&
      `${item.path.split('/').slice(0, 3).join('/')}/` === previousPath
  );

  return sortSitemapPagesByTitle(sitemapPages).map(({ path, context }) => ({
    item: { title: context.title, path },
    children: [],
  }));
};

const createSecondLevel = (
  filteredPages: AllSitePageNodeType[],
  previousPath: string
): SitemapItem[] => {
  const sitemapPages = filteredPages.filter(
    (item) =>
      item.context.level === SECOND_LEVEL_PAGE_LEVEL &&
      `/${item.path.split('/')[1]}/` === previousPath
  );

  return sortSitemapPagesByTitle(sitemapPages).map(({ path, context }) => ({
    item: { title: context.title, path },
    children: createThirdLevel(filteredPages, path),
  }));
};

const createFirstLevelGroups = (
  firstLevelArray: SitemapLink[],
  filteredPages: AllSitePageNodeType[]
): SitemapItem[] =>
  firstLevelArray.map((item, index) => {
    if (index === 0) return { item, children: [] };

    return {
      item,
      children: createSecondLevel(filteredPages, item.path),
    };
  });

export const createOtherPagesList = (allSitePages: AllSitePageNodeType[]): SitemapLink[] => {
  const otherPages = allSitePages.filter(
    ({ componentChunkName }) => componentChunkName === ldsPageComponentChunkName
  );

  return sortSitemapPagesByTitle(otherPages).map(({ context, path }) => ({
    title: context.title,
    path,
  }));
};

export const createSitemapList = (
  firstLevelArray: SitemapLink[],
  allSitePages: AllSitePageNodeType[]
): SitemapItem[] => {
  const filteredPages = allSitePages
    .filter(
      ({ path, componentChunkName, context }) =>
        !DEFAULT_EXCLUDED_PAGES.includes(path) &&
        componentChunkName !== articlePageComponentChunkName &&
        componentChunkName !== ldsPageComponentChunkName &&
        !context.pageConfiguration?.hideFromSitemap
    )
    .sort((a, b) => a.path.split('/').length - b.path.split('/').length);

  return createFirstLevelGroups(firstLevelArray, filteredPages);
};
